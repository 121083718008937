// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { DateRangePicker } from "@fas/cpa-cabinet-ui";
import type { ChangeTableFilters } from "@fas/cpa-state-manager/redux/actions/table";
import en from "dayjs/locale/en-gb";
import {
  DateCellComponent,
  DateInputComponent,
  FooterComponent,
} from ".";
import type { Props } from "./types/DateRangePicker.types";

const DateRangePickerComponent: StatelessFunctionalComponent<Props> = ({
  filters,
  onFiltersChange,
  disabled = false,
}: Props) => (
  <DateRangePicker
    // $FlowFixMe filters type contains array but this must be string
    startDate={filters.dateFrom}
    // $FlowFixMe filters type contains array but this must be string
    endDate={filters.dateTo}
    onChangeDate={({
      startDate,
      endDate,
    }: { startDate: string, endDate: string }): ChangeTableFilters => (
      onFiltersChange({ dateFrom: startDate, dateTo: endDate }))}
    onChangePreset={({
      startDate,
      endDate,
    }: { startDate: string, endDate: string }): ChangeTableFilters => (
      onFiltersChange({ dateFrom: startDate, dateTo: endDate }))}
    locale={en}
    DateCellComponent={DateCellComponent}
    FooterComponent={FooterComponent}
    DateInputComponent={DateInputComponent}
    disabled={disabled}
  />
);

export default DateRangePickerComponent;
